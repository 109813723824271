<template>

  <div>
    <div class="ecode_title">专属二维码</div>
    <div class="ecode_cont">
      <div id="qrCode" ref="qrCodeDiv" class="qrCodes"></div>
      
    </div>
  </div>

</template>

<script>
import QRCode from 'qrcodejs2'

export default {
  data () {
    return {
      dialogVisible: false,
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.bindQRCode();
    })
  },
  methods: {
    bindQRCode: function () {
      new QRCode(this.$refs.qrCodeDiv, {
        text: 'http://www.kandashi.com.cn/#/login?'+'S=S'+localStorage.getItem('UserId'),
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L,//容错率，L/M/H

      })
    },
  }
}
</script>

<style>
.ecode_title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  color: #141414;
  border-bottom: 1px solid #e6e6e6;
}
.ecode {
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.ecode_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qrCodes {
  margin-bottom: 50px;
  margin-top: 50px;
}
</style>